import axios from 'axios';
import { API_URL } from './CommonComp/APIConstants';
// import { toast } from 'sonner';

const APIHandler = axios.create({
    baseURL: API_URL, 
});

APIHandler.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        const tenantCode = localStorage.getItem('X-Tenant-Code');

        if (!tenantCode) {
            return Promise.reject(new Error('Tenant code is missing in headers.'));
        }

        config.headers['Authorization'] = token ? `Bearer ${token}` : '';
        config.headers['X-Tenant-Code'] = tenantCode;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


const get = async (url, config = {}) => {
    try {
        const response = await APIHandler.get(url, config);
        console.log("API Handler Response: ", response);
        
        return response;
    } catch (error) {
        if (error.response.status == 400) {
            // toast.error((error.response?.data || error.message), {
            //     variant: 'error',
            //     closeButton: true,
            //     duration: 1500
            // });
        } else {
            throw error.response ? error.response.data : error;
            // if (error.response.status == 400) {
            //     toast.error((error.response?.data || error.message), {
            //         variant: 'error',
            //         closeButton: true,
            //         duration: 1500
            //     });
            // }
        }
    }
};


const post = async (url, data, config = {}) => {
    try {
        const response = await APIHandler.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                ...config.headers
            },
            ...config
        });

        return response.data;
    } catch (error) {
        if (error.response.status == 400) {
            // toast.error((error.response?.data || error.message), {
            //     variant: 'error',
            //     closeButton: true,
            //     duration: 1500
            // });
        } else {
            throw error.response ? error.response.data : error;
            // if (error.response.status == 400) {
            //     toast.error((error.response?.data || error.message), {
            //         variant: 'error',
            //         closeButton: true,
            //         duration: 1500
            //     });
            // }
        }
    }
};

export { get, post };
